var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"list px-3 mx-auto"},[_c('v-col',[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.visualTasks,"show-expand":"","single-expand":"","disable-pagination":"","hide-default-footer":true},on:{"item-expanded":_vm.loadDetailsVisual},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.onExportTaskResults(item.id, 'visual')}}},[_vm._v(" Export ")])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[(!isExpanded)?_c('v-icon',[_vm._v(" mdi-arrow-down ")]):_vm._e(),(isExpanded)?_c('v-icon',[_vm._v(" mdi-arrow-up ")]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('vue-json-pretty',{attrs:{"data":item.data}})],1)]}}],null,true)})],1)],1),_c('v-col',[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.classificationTasks,"show-expand":"","single-expand":"","disable-pagination":"","hide-default-footer":true},on:{"item-expanded":_vm.loadDetailsClassification},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.onExportTaskResults(item.id, 'classification')}}},[_vm._v(" Export ")])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[(!isExpanded)?_c('v-icon',[_vm._v(" mdi-arrow-down ")]):_vm._e(),(isExpanded)?_c('v-icon',[_vm._v(" mdi-arrow-up ")]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('vue-json-pretty',{attrs:{"data":item.data}})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }