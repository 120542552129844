import { AnnotationType } from "@/models/enums/AnnotationType";

export class Category {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public annotationType: AnnotationType
  ) {}
}
