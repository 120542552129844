import { Question } from "@/models/Question";
import { QuestionChoice } from "@/models/QuestionChoice";
import { Photo } from "@/models/Photo";

export class QuestionAnswerExport {
  constructor(
    public id: number,
    public photo: Photo,
    public question: Question,
    public choice: QuestionChoice,
    public comment: string,
    public user_id: number
  ) {}
}
