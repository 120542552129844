
















































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
// eslint-disable-next-line no-unused-vars
import { VisualTaskEntity } from "@/entities/VisualTask.entity";
import { taskService } from "@/services/task.service";
import { annotationService } from "@/services/annotation.service";
import { exportMapper } from "@/mappers/export.mapper";
// eslint-disable-next-line no-unused-vars
import { VisualExportData } from "@/models/VisualExportData";
// eslint-disable-next-line no-unused-vars
import { ClassificationTaskEntity } from "@/entities/ClassificationTaskEntity";
import { questionService } from "@/services/question.service";
import { ClassificationExportData } from "@/models/ClassificationExportData";

@Component({
  components: {
    VueJsonPretty
  }
})
export default class Export extends Vue {
  // Data
  visualTasks: VisualTaskEntity[] = [];
  visualExportData: VisualExportData[] = [];
  headers = [
    { text: "Name", align: "start", sortable: false, value: "name" },
    { text: "Actions", value: "actions", sortable: false },
    { text: "", align: "end", value: "data-table-expand", sortable: false }
  ];

  classificationTasks: ClassificationTaskEntity[] = [];
  classificationExportData: ClassificationExportData[] = [];

  // Mounted
  mounted() {
    this.fetchData();
  }

  // Methods
  fetchData() {
    taskService.getVisualTasks().then(response => {
      this.visualTasks = response.data;
      this.getExportDataForVisualTasks();
    });
    taskService.getClassificationTasks().then(response => {
      this.classificationTasks = response.data;
      this.getExportDataForClassificationTasks();
    });
  }

  getExportDataForClassificationTasks() {
    this.classificationTasks.forEach((task: ClassificationTaskEntity) => {
      questionService.getExportAnswersForTask(task.id).then(response => {
        let singleExportData = exportMapper.mapClassificationExportData(
          response.data
        );
        singleExportData.taskId = task.id;
        this.classificationExportData.push(singleExportData);
      });
    });
  }

  getExportDataForVisualTasks() {
    this.visualTasks.forEach((task: VisualTaskEntity) => {
      annotationService
        .getExportAnnotationDataForTask(task.id)
        .then(response => {
          let singleExportData = exportMapper.mapVisualExportData(
            response.data
          );

          singleExportData.taskId = task.id;
          this.visualExportData.push(singleExportData);
        });
    });
  }

  getExportDataForVisualTask(id: number): VisualExportData {
    const exportDataForTask = this.visualExportData.filter(singleExportData => {
      return singleExportData.taskId == id;
    });

    // This can't happen
    if (exportDataForTask.length == 0) {
      console.error(`Export data for taskId=${id} doesn't exist`);
      return new VisualExportData(0, []);
    }
    if (exportDataForTask.length != 1) {
      console.error(`There is more than 1 export data for taskId=${id}`);
      return new VisualExportData(0, []);
    }

    return exportDataForTask[0].withoutEmptyAnnotations();
  }

  getExportDataForClassificationTask(id: number): ClassificationExportData {
    const exportDataForTask = this.classificationExportData.filter(
      singleExportData => {
        return singleExportData.taskId == id;
      }
    );

    // This can't happen
    if (exportDataForTask.length == 0) {
      console.error(`Export data for taskId=${id} doesn't exist`);
      return new ClassificationExportData(0, []);
    }
    if (exportDataForTask.length != 1) {
      console.error(`There is more than 1 export data for taskId=${id}`);
      return new ClassificationExportData(0, []);
    }

    return exportDataForTask[0];
  }

  getResultsForTask(id: number, type: string): string {
    let singleExportData;
    if (type == "visual") {
      singleExportData = this.getExportDataForVisualTask(id);
    } else {
      singleExportData = this.getExportDataForClassificationTask(id);
    }
    return JSON.stringify(singleExportData, null, "\t");
  }

  onExportTaskResults(taskId: number, type: string) {
    const data = this.getResultsForTask(taskId, type);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";

    const blob = new Blob([data], { type: "octet/stream" });
    const url = window.URL.createObjectURL(blob);
    const fileName = `${type}-task${taskId}-results.json`;
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  loadDetailsVisual({ item }: any) {
    item.data = this.getExportDataForVisualTask(item.id);
  }

  loadDetailsClassification({ item }: any) {
    item.data = this.getExportDataForClassificationTask(item.id);
  }
}
