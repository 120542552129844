import { MiscEntity } from "@/entities/Misc.entity";
import { AnnotationExport } from "@/models/AnnotationExport";

export class PhotoExport {
  constructor(
    public photoId: number,
    public photoName: string,
    public misc: MiscEntity[],
    public annotations: AnnotationExport[]
  ) {}
}
