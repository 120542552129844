import { VisualExportEntity } from "@/entities/VisualExport.entity";
import { VisualExportData } from "@/models/VisualExportData";
import { Annotation } from "@/models/Annotation";
import { AnnotationPointEntity } from "@/entities/AnnotationPoint.entity";
import { Point } from "@/models/Point";
import { Category } from "@/models/Category";
import { CategoryEntity } from "@/entities/Category.entity";
import { AnnotationExportEntity } from "@/entities/AnnotationExport.entity";
import { PhotoExport } from "@/models/PhotoExport";
import { AnnotationType } from "@/models/enums/AnnotationType";
import { ClassificationExportData } from "@/models/ClassificationExportData";
import { QuestionAnswerExportEntity } from "@/entities/QuestionAnswerExport.entity";
import { QuestionAnswerExport } from "@/models/QuestionAnswerExport";
import { Photo } from "@/models/Photo";
import { Question } from "@/models/Question";
import { QuestionChoice } from "@/models/QuestionChoice";
import { AnnotationExport } from "@/models/AnnotationExport";

class ExportMapper {
  mapAnnotationPoint(point: AnnotationPointEntity): Point {
    return new Point(point.x, point.y);
  }

  mapCategory(category: CategoryEntity): Category {
    return new Category(
      category.id,
      category.name,
      category.description,
      category.visual_selection_style == 1
        ? AnnotationType.Polygons
        : AnnotationType.Rectangles
    );
  }

  mapAnnotationExportToAnnotation(
    annotationExportEntity: AnnotationExportEntity
  ): AnnotationExport {
    let mappedPoints: Point[] = [];

    annotationExportEntity.points.forEach(point => {
      mappedPoints.push(this.mapAnnotationPoint(point));
    });

    let mappedCategory = this.mapCategory(annotationExportEntity.category);

    return new AnnotationExport(
      annotationExportEntity.id,
      annotationExportEntity.visual_task,
      annotationExportEntity.photo,
      mappedCategory.id,
      annotationExportEntity.user,
      annotationExportEntity.is_finished,
      mappedCategory.annotationType,
      mappedPoints
    );
  }

  mapAnnotationExportsToAnnotations(
    annotationExportEntities: AnnotationExportEntity[]
  ): AnnotationExport[] {
    return annotationExportEntities.map(annotationExportEntity => {
      return this.mapAnnotationExportToAnnotation(annotationExportEntity);
    });
  }

  mapPhoto(exportEntity: QuestionAnswerExportEntity): Photo {
    return new Photo(exportEntity.photo.id, exportEntity.image_name);
  }

  mapQuestion(exportEntity: QuestionAnswerExportEntity): Question {
    return new Question(
      exportEntity.question.id,
      exportEntity.question.question_statement
    );
  }

  mapQuestionChoice(exportEntity: QuestionAnswerExportEntity): QuestionChoice {
    return new QuestionChoice(
      exportEntity.choice.id,
      exportEntity.choice.choice_statement
    );
  }

  mapClassificationExportData(
    exportEntities: QuestionAnswerExportEntity[]
  ): ClassificationExportData {
    let exportData = new ClassificationExportData(0, []);

    let hasAnnotations = exportEntities.length > 0;

    if (!hasAnnotations) {
      return exportData;
    }

    exportData.taskId = exportEntities[0].task;

    exportEntities.forEach(exportEntity => {
      exportData.answers.push(
        new QuestionAnswerExport(
          exportEntity.id,
          this.mapPhoto(exportEntity),
          this.mapQuestion(exportEntity),
          this.mapQuestionChoice(exportEntity),
          exportEntity.comment,
          exportEntity.user
        )
      );
    });

    return exportData;
  }

  mapVisualExportData(exportEntities: VisualExportEntity[]): VisualExportData {
    let exportData = new VisualExportData(0, []);

    exportEntities.forEach(exportEntity => {
      exportEntity.misc = exportEntity.misc.filter(misc => {
        return misc.comment != "" || misc.is_bad;
      });
    });

    let hasAnnotations = false;
    let taskId = 0;

    exportEntities.forEach(exportEntity => {
      if (exportEntity.annotations.length > 0 || exportEntity.misc.length > 0) {
        hasAnnotations = true;
        taskId = exportEntity.visual_task;
      }
    });

    if (!hasAnnotations) {
      return exportData;
    }
    exportData.taskId = taskId;

    exportEntities.forEach(exportEntity => {
      const annotations = this.mapAnnotationExportsToAnnotations(
        exportEntity.annotations
      );

      exportData.photos.push(
        new PhotoExport(
          exportEntity.photo_id,
          exportEntity.photo_name,
          exportEntity.misc,
          annotations
        )
      );
    });

    return exportData;
  }
}

// Export a singleton instance in the global namespace
export const exportMapper = new ExportMapper();
