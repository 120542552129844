import { AnnotationType } from "@/models/enums/AnnotationType";
import { Point } from "@/models/Point";

export class AnnotationExport {
  constructor(
    public id: number,
    public taskId: number,
    public photoId: number,
    public categoryId: number,
    public userId: number,
    public isFinished: boolean,
    public type: AnnotationType,
    public points: Point[]
  ) {}
}
