import { PhotoExport } from "@/models/PhotoExport";

export class VisualExportData {
  constructor(public taskId: number, public photos: PhotoExport[]) {}

  public withoutEmptyAnnotations(): VisualExportData {
    let exportData = this;

    exportData.photos.forEach(photoExport => {
      photoExport.annotations = photoExport.annotations.filter(annotation => {
        return annotation.points.length > 0;
      });
    });

    return exportData;
  }
}
